export const SET_USER_ROLE = 'SET_USER_ROLE';
export const SET_USER_ROLES = 'SET_USER_ROLES';
export const SET_SUPER_USER = 'SET_SUPER_USER';
export const SET_ORG_CREATED = 'SET_ORG_CREATED';
export const SET_ACCOUNT_ENTITY = 'SET_ACCOUNT_ENTITY';
export const SET_ACCOUNT_ENTITY_ID = 'SET_ACCOUNT_ENTITY_ID';

export const setUserRole = (role) => ({
  type: SET_USER_ROLE,
  payload: role,
});

export const setUserRoles = (roles) => ({
  type: SET_USER_ROLES,
  payload: roles,
});

export const setSuperUser = (isSuperUser) => ({
  type: SET_SUPER_USER,
  payload: isSuperUser,
});

export const setOrgCreated = (is_org_created) => ({
  type: SET_ORG_CREATED,
  payload: is_org_created,
});

export const setAccountEntity = (entity) => ({
    type: SET_ACCOUNT_ENTITY,
    payload: entity,
  });

export const setAccountEntityId = (id) => ({
    type: SET_ACCOUNT_ENTITY_ID,
    payload: id,
  });